<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-between uk-margin">
            <div class="uk-flex uk-flex-left">
                <div class="uk-inline">
                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                    <input class="uk-input" v-model="keywords" @keyup.enter="changeMeta('name')" type="text" placeholder="Search MyWarung">
                </div>
            </div>
            <div v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])" class="uk-flex uk-flex-right">
                <button
                    class="uk-button uk-border-rounded uk-margin-right"
                    type="button"
                    style="background-color: #009688; color: #fff; padding: 0 10px;"
                    @click="$router.push({ name: 'MyWarungLimit', query: { keywords_warung: $route.query.keywords } })"
                >
                    Mitra Limit
                </button>
                <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="showModal(null)">Create</button>
            </div>
        </div>
        <div class="uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr class="uk-text-left">
                            <th>Warung Name</th>
                            <th>Address</th>
                            <th>Assigned Mitra(s)</th>
                            <th>Balance</th>
                            <th class="uk-width-auto uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="7"/>
                    <tbody v-else-if="page_data.docs.length>0">
                        <tr v-for="(data, i) in page_data.docs" :key="i" class="uk-text-left">
                            <td><div class="one-line-text">{{ data.name }}</div></td>
                            <td><div class="one-line-text">{{ data.address }}</div></td>
                            <td><div class="one-line-text">{{ data.count_customers }}</div></td>
                            <td><div class="one-line-text" :class="[data.balance < 0 && 'uk-text-danger']">Rp{{ formatter.thousandSeparator(data.balance) }}</div></td>
                            <td class="uk-text-center">
                                <button class="uk-button uk-button-small uk-button-default" type="button" >Actions</button>
                                <div uk-dropdown="mode: click">
                                    <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                        <li><a @click="openWarungDetail(data._id)">Details</a></li>
                                        <li class="uk-nav-divider"></li>
                                        <template v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])">
                                            <li><a @click="showModal(data)">Edit</a></li>
                                            <li class="uk-nav-divider"></li>
                                            <li><a @click="showModalDelete(data)">Delete</a></li>
                                        </template>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="5"/>
                </table>
            </div>
            <pagination
                :total-data="page_data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
                :limit="meta.limit"
            />
        </div>
        <CreateModal v-if="showCreateModal" :data="form" @hideModal="hideModal" />
        <ConfirmDeleteModal v-if="deleteData.isShow" :data="deleteData" @hideModal="hideModal" @confirmDelete="confirmDelete" />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import Pagination from '@/components/globals/Pagination';
import CreateModal from '@/components/modals/community/mywarung/create_warung';
import ConfirmDeleteModal from '@/components/globals/modals/confirm_delete';
import { getUserLogin } from '@/utils/auth';
import formatter from "@/utils/formatter";
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';

export default {
    data(){
        return {
            user_cred: getUserLogin(),
            meta: {
                limit: 10,
                page: 1,
                name: this.$route.query.keywords ?? null
            },
            form: {
                _id: null,
                name: '',
                address: '',
                start_balance: 0,
            },
            keywords: this.$route.query.keywords ?? null,
            page_data: { docs: [], totalDocs: 0 },
            isLoading: true,
            isEdit: false,
            showCreateModal: false,
            formatter,
            deleteData: {
                isShow: false,
                title: 'Are you sure to delete this Warung?',
                _id: null,
                body: {
                    "Warung Name": null,
                    "Address": null,
                }
            }
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable,
        CreateModal,
        ConfirmDeleteModal,
    },
    watch: {
        meta() {
            this.getInitPageData();
        },
    },
    async mounted() {
        await this.getInitPageData();
    },
    methods: {
        ...mapActions({
            getWarung: 'warung/getWarung',
            deleteWarung: 'warung/deleteWarung',
        }),
        async getInitPageData(){
            this.isLoading = true;
            this.page_data = await this.getWarung(this.meta);
            this.isLoading = false;
        },
        changeMeta(prop) {
            if (this.keywords) {
                this.$router.push(`mywarung?keywords=${this.keywords}`);
            } else {
                this.$router.push(`mywarung`);
            }
            this.meta = {
                ...this.meta,
                [prop]: this.keywords
            };
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        resetForm() {
            this.form = {
                _id: null,
                name: '',
                address: '',
                start_balance: 0,
            };
        },
        showModal(data){
            if (data) {
                delete this.form.start_balance;
                this.form._id = data._id;
                this.form.name = data.name;
                this.form.address = data.address;
            }
            
            this.showCreateModal = true;
        },
        showModalDelete(data){
            this.deleteData._id = data._id;
            this.deleteData.body["Warung Name"] = data.name;
            this.deleteData.body["Address"] = data.address;
            
            this.deleteData.isShow = true;
        },
        hideModal(isRefresh){
            if (isRefresh) this.getInitPageData();
            
            this.showCreateModal = false;
            this.deleteData.isShow = false;
            this.resetForm();
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        async confirmDelete(id) {
            this.isLoading = true;
            const response = await this.deleteWarung(id);
            if (response && response.status === 'OK') {
                notificationSuccess('deleted!');
                this.hideModal(true);
            } else {
                this.isLoading = false;
                notificationDanger('Failed to delete!');
            }
        },
        openWarungDetail(id) {
            const searchKey = this.$route.query.keywords;
            if (searchKey) {
                this.$router.push(`mywarung/${id}?keywords=${searchKey}`);
            } else {
                this.$router.push(`mywarung/${id}`);
            }
        },
    },
};
</script>

<style scoped>
.one-line-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
}
</style>
